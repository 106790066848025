<template>
  <ul class="filter-options__list">
    <li class="filter-options__list-item filter-options__list_title">
      {{ $t(`filter.${name}`) }}
    </li>

    <li
      v-for="(item, index) in currentFiltersList"
      :key="index"
      class="filter-options__list-item filter-options__list_block"
      :class="{
        'filter-options__list_active': nexBlockElementName.includes(item.name),
      }"
      @mouseover="hoverHandler(item)"
    >
      <!-- 'filter-options__list_disabled': !Boolean(item.data_name), -->
      <div class="options-element">
        <div class="options-element__title">
          {{ $t(`filter.${item.name}`) }}
          <!-- {{ item.id }}
          {{ item.counter }} -->
        </div>


        <div v-if="item.counter" class="options-element__counter">
          <FilterCounter
            :value="item.counter"
            @clear="clearFilterHandler(item.name)"
          />
        </div>

        <div v-if="item.isHasList" class="options-element__arrow-block">
          <svg-icon name="arrow_grey_right" class="element-arrow" />
        </div>
      </div>
    </li>
  </ul>
</template>

<script>
import { mapGetters } from 'vuex'
import FilterCounter from './FilterCounter.vue'

export default {
  components: {
    FilterCounter,
  },
  props: {
    name: {
      type: String,
      default: '',
    },
    currentFiltersList: {
      type: Array,
      default: () => [],
    },
    level: {
      type: Number,
      default: 1,
    },
  },

  data() {
    return {
      nexBlockElementName: '',
    }
  },

  computed: {
    // ...mapGetters(['']),
  },

  methods: {
    hoverHandler(item) {
      this.nexBlockElementName = item.name
      this.$emit('hover-block-name', {
        nextBlockName: item.name,
        level: this.level + 1,
        id: item.id,
      })
    },
    clearFilterHandler(name) {
      
    },
  },
}
</script>

