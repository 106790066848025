<template>
  <keep-alive>
    <CurrentFilterList
      :current-filters-list="getCurrentFilterListyByName(name)"
      :level="2"
      name="tour_operators"
      @hover-block-name="$emit('hover-block-name', $event)"
    />
  </keep-alive>
</template>

<script>
import { mapGetters } from 'vuex'
import CurrentFilterList from './CurrentFilterList.vue'

export default {
  components: { CurrentFilterList },

  props: {
    name: {
      type: String,
      default: 'tour_operator',
    },
  },

  computed: {
    ...mapGetters(['getCurrentFilterListyByName']),
  },

  data() {
    return {}
  },

  methods: {},
}
</script>

