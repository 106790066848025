<template>
  <div class="filter-options filter-options__wrapper">
    <!-- {{ getAllRegistryFiltersForBackend(getCurrentFilterRole) }} -->
    <!-- {{ getTourOperatorCounter }} -->

    <div class="filter-options__container">
      <CurrentFilterList
        :current-filters-list="getCurrentFilterListyByName('main')"
        name="filters_list"
        :level="1"
        @hover-block-name="generatingComponents($event)"
      />

      <keep-alive v-for="(component, index) in dynamicComponents" :key="index">
        <component
          :is="component.name"
          :level="component.level"
          :role="getCurrentFilterRole"
          class="filter-options__block"
          :class="{ 'is-show': component.isShow }"
          @hover-block-name="generatingComponents($event)"
        />
      </keep-alive>
    </div>

    <div class="filter-options__control">
      <!-- {{ listNameLvl1 }} - {{ listNameLvl2 }} - {{ listNameLvl3 }} -->
      <div class="filter-options__counter">
        {{ $t('filter.selected_filters.is_select') }}
        <b>{{ getSelectedFiltersCounter }}</b>
        {{ $t('filter.selected_filters.filters') }}
      </div>

      <div class="filter-options__btn-wrapper">
        <Button
          medium
          transparent
          class="filter-options__btn"
          @click.native="clearAllFiltersHandler"
        >
          {{ $t('buttons.clear_filter') }}
        </Button>
        <Button
          medium
          :disabled="!isSelectedFilters"
          class="filter-options__btn"
          @click.native="filterHandler"
        >
          {{ $t('buttons.to_filter') }}
        </Button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { generateFilterBlockName } from '@/utils/filterBlockName'

import CurrentFilterList from './CurrentFilterList.vue'

import FilterTourOperatorBlock from './FilterTourOperatorBlock.vue'
import FilterHotelBlock from './FilterHotelBlock.vue'
import FilterRegionsBlock from './FilterRegionsBlock.vue'
import FilterActivityTypeBlock from './FilterActivityTypeBlock.vue'
import FilterActivityPeriodBlock from './FilterActivityPeriodBlock.vue'
import FilterDistrictBlock from './FilterDistrictBlock.vue'
import FilterSettlementBlock from './FilterSettlementBlock'
import FilterStarsBlock from './FilterStarsBlock.vue'
import FilterConferenceHallsBlock from './FilterConferenceHallsBlock.vue'
import FilterTotalRoomsBlock from './FilterTotalRoomsBlock.vue'
import FilterBedsQuantityBlock from './FilterBedsQuantityBlock.vue'

export default {
  components: {
    CurrentFilterList,
    FilterTourOperatorBlock,
    FilterHotelBlock,
    FilterRegionsBlock,
    FilterActivityTypeBlock,
    FilterActivityPeriodBlock,
    FilterConferenceHallsBlock,
    FilterDistrictBlock,
    FilterSettlementBlock,
    FilterTotalRoomsBlock,
    FilterBedsQuantityBlock,
    FilterStarsBlock,
  },
  computed: {
    ...mapGetters([
      'isSelectedFiltersBackEnd',
      'getCurrentFilterListyByName',
      'getAllRegistryFiltersForBackend',
      'getCurrentFilter',
      'getCountersQuantity',
      'getCurrentFilterRole',
      'checkIfBoothRoleDataSelected',
      'getSelectedFiltersCounter',
    ]),

    isSelectedFilters() {
      return this.isSelectedFiltersBackEnd.isSomeFiltersSelected
    },
  },

  data() {
    return {
      dynamicComponents: [],
    }
  },
  methods: {
    generatingComponents(block) {
      // get role for next filter blocks and separate state for every role
      if (block.nextBlockName === 'hotel') {
        this.$store.commit('SET_FILTER_ROLE', 'hotel')
      } else if (block.nextBlockName === 'tour_operator') {
        this.$store.commit('SET_FILTER_ROLE', 'tour_operator')
      }

      const componentName = generateFilterBlockName(block.nextBlockName) // just name of component by example FilterTourOperatorBlock
      let existingComponent = this.dynamicComponents.find(
        // find component in dynamicComponents by level
        (c) => c.level === block.level
      )

      if (!existingComponent) {
        // if component not exist in dynamicComponents then add it
        this.dynamicComponents.push({
          name: componentName,
          id: block.id,
          isShow: true,
          ...block,
        })
      } else {
        // if component exist in dynamicComponents then change it
        existingComponent = Object.assign(existingComponent, {
          name: componentName,
          isShow: true,
          ...block,
        })
        // we are handling isShow key because we need to save dynamicComponent in DOM and just change property display in css
        // change key isShow to false for every component in dynamicComponents if existingComponent level is less than every component in dynamicComponents
        this.dynamicComponents.map((c) => {
          if (c.level > existingComponent.level) {
            c.isShow = false
          }
          return c
        })
      }
    },

    async filterHandler() {
      await this.$store.commit('SET_TABLE_SORT_DATA', { page: 1 })

      if (this.isSelectedFiltersBackEnd.isBothRoleFiltersSelected) {
        await this.$store.dispatch('getRegistryPageTableData', {
          role: 'tour_operator',
        })
        await this.$store.dispatch('getRegistryPageTableData', {
          role: 'hotel',
        })
      } else {
        await this.$store.dispatch('getRegistryPageTableData', {
          role: this.getCurrentFilterRole,
        })
      }

      this.$emit('filtered', true)

      // if (this.isSelectedFiltersBackEnd.isTourOperatorFiltersSelected) {
      //   this.$store.commit('SET_CLEAR_HOTEL_FILTERS')
      // } else if (this.isSelectedFiltersBackEnd.isHotelFiltersSelected) {
      //   this.$store.commit('SET_CLEAR_TOUR_OPERATOR_FILTERS')
      // }
    },
    async clearAllFiltersHandler() {
      // await this.$store.dispatch('clearCurrentFilterSection', 'clearAll')
      this.$store.commit('SET_CLEAR_ALL_FILTERS')

      await this.$store.dispatch('getRegistryTableData')

      this.$emit('filtered', false)
    },
  },
}
</script>

<style lang="sass">
.filter-options__block
  display: none
  &.is-show
    display: block
</style>

