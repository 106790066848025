<template>
  <ul class="filter-options__list">
    <!-- {{
      currentFiltersListLength
    }}
    {{
      currentFilterListId
    }}
    {{
      selectedItems.length
    }}
    {{
      role
    }} -->

    <li class="filter-options__list-item filter-options__list_title">
      {{ $t(`filter.${name}`) }}
    </li>

    <li
      v-for="item in currentFiltersList"
      :key="item.id"
      class="filter-options__list-item filter-options__list_block"
      :class="{
        'filter-options__list_active':
          item.id === heveredCssItem && name !== 'settlement',
      }"
      @mouseover="hoverHandler(item)"
      @mouseleave="unHoverHandler(item)"
    >
      <!-- {{ name }} -->
      <div class="options-element">
        <div class="options-element__checkbox-wrapper">
          <checkbox
            v-model="checkedItems"
            :value="item"
            class="options-element__checkbox"
          />
        </div>
        <div class="options-element__title">
          {{ item.name }}
        </div>

        <div
          v-if="item.counter"
          class="options-element__counter options-checkbox__counter"
        >
          <FilterCounter
            :value="item.counter"
            @clear="clearFilterHandler(item)"
          />
        </div>

        <div v-if="item.isHasList" class="options-element__arrow-block">
          <svg-icon name="arrow_grey_right" class="element-arrow" />
        </div>
      </div>
    </li>
  </ul>
</template>

<script>
import { mapGetters } from 'vuex'
import FilterCounter from './FilterCounter.vue'
import { getDateRangesAgoFromNow } from '@/library/time'

export default {
  components: {
    FilterCounter,
  },
  props: {
    role: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    currentFiltersList: {
      type: Array,
      default: () => [],
    },
    selectedItems: {
      type: Array,
      default: () => [],
    },
    currentFilterListId: {
      type: Number,
      default: 0,
    },
    level: {
      type: Number,
      default: 1,
    },
    filterName: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      nexBlockElementName: '',
      hoveredItem: null,
      heveredCssItem: null,
    }
  },

  computed: {
    ...mapGetters([
      'getCurrentFilter',
      'getCountersQuantity',
    ]),

    isSelectedFilters() {
      return !!Object.keys(this.getCurrentFilter).length
    },

    currentFiltersListLength() {
      return this.currentFiltersList?.length
    },

    checkedItems: {
      get() {
        return this.selectedItems
      },
      set(val) {

        this.$emit('set-selected-list', val)

        // if (
        //   this.filterName !== 'location_settlement' &&
        //   this.filterName !== 'location_district'
        // ) {
        //   this.$store.commit('SET_FILTER', {
        //     filter: this.filterName,
        //     name: this.filterName,
        //     value: val.map((el) => el.id).join(','),
        //   })
        // }

        // if (this.filterName === 'date_of_state_registration') {
        //   this.$store.commit('SET_FILTER', {
        //     filter: null,
        //     name: 'date_of_state_registration',
        //     value: val
        //       .map((el) => {
        //         const from = getDateRangesAgoFromNow(el?.period)?.from
        //         const to = getDateRangesAgoFromNow(el?.period)?.to
        //         return `${from}, ${to}`
        //       })
        //       .join(','),
        //   })
        // }
      },
    },
  },

  methods: {
    clearFilterHandler(item) {
      if (item.id && item.state_id) {
        this.$store.commit(
          'CLEAR_SETTLEMENTS_OF_TOUR_OPERATOR_BY_DISTIRCT_ID',
          item.id
        )
      }
      // this.$emit('clear-filter', item)
    },

    unHoverHandler(item) {

      if (this.hoveredItem === item.id) {
        this.nexBlockElementName = ''
        this.hoveredItem = null
      }
    },

    hoverHandler(item) {
      let currentHoverItem = item.id
      if (this.hoveredItem !== currentHoverItem) {
        this.nexBlockElementName = item.nextBlockName

        this.$emit('hover-block-name', {
          nextBlockName: item.nextBlockName,
          level: this.level + 1,
          id: item.id,
        })

        this.$emit('set-list', item)
        this.$emit('set-selected-list-id', item.id)

        this.hoveredItem = item.id
      }
      this.heveredCssItem = item.id
    },
  },
}
</script>

<style lang="sass" scoped>
.options-checkbox__counter
  margin: 0 5px 0 0
</style>

