<template>
  <!-- <div style="width: 100%"> -->
  <!-- getCurrentFilterListyByName >> -->
  <!-- {{ getSelectedFiltersByRoleAndNameOfFilter(role, filterName) }} -->
  <!-- <br /> -->
  <!-- <br /> -->
  <!-- {{ getSelectedFiltersByRoleAndNameOfFilter(role, 'location_district') }} -->
  <CurrentCheckoxesList
    :filter-name="filterName"
    :role="role"
    :name="name"
    :level="level"
    :currentFilterListId="listId"
    :currentFiltersList="getFilterListOfTerritories(role, 'regions', 'settlements')"
    :selected-items="getSelectedFiltersByRoleAndNameOfFilter(role, filterName)"
    @set-selected-list="getSelectedList($event)"
  />
  <!-- </div> -->
</template>

<script>
import { mapGetters } from 'vuex'
import CurrentCheckoxesList from './CurrentCheckoxesList.vue'
export default {
  components: { CurrentCheckoxesList },

  props: {
    role: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: 'settlement',
    },
    level: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      currFilterList: [],
      listId: null,
      filterName: 'location_settlement',
    }
  },
  computed: {
    ...mapGetters([
      'getSelectedFiltersByRoleAndNameOfFilter',
      'getFilterListOfTerritories',
      'getCurrentFilterListyByName',
      'getSelectedFiltersByRole',
    ]),
  },

  created() {},

  methods: {
    async getSelectedList(selectedSettlements) {

      const filters = this.getCurrentFilterListyByName(this.role);
      const selectedFilters = this.getSelectedFiltersByRole(this.role)

      this.setDistrictFilter(selectedSettlements, filters, selectedFilters)

      this.$store.dispatch('updateFiltersByType', {
        selected_list_type: 'settlements',
        role: this.role,
        filter_name: this.filterName,
        [this.filterName]: selectedSettlements,
      })
    },

    setDistrictFilter(selectedSettlements, filters, selectedFilters) {
      const districtIds = [...new Set(selectedSettlements.map(item => item.district_id))];
      let districts = [];

      for(let i = 0; i < filters.length; i++) {
        if(filters[i].name === 'regions') {
          filters[i].districts.forEach(district => {
            if(districtIds.includes(district.id)) {
              districts.push(district);
            }
          });
          break;
        }
      }

      for(let i = 0; i < selectedFilters.location_district.length; i++) {
          districts.push(selectedFilters.location_district[i]);
      }

      const districtsItems = Object.values(
        districts.reduce((acc, obj) => ({ ...acc, [obj.id]: obj }), {})
      );

      districtsItems.forEach(district => {
        district.counter = 0
        selectedSettlements.forEach(el => {
          if(el.district_id === district.id) {
            district.counter++
          }
        })
      })

      this.$store.dispatch('updateFiltersByType', {
        selected_list_type: 'districts',
        role: this.role,
        filter_name: 'location_district',
        location_district: districtsItems,
      });

      this.setRegionFilter(districts, filters, selectedFilters)
    },

    setRegionFilter(districts, filters, selectedFilters) {
      const regionsIds = [...new Set(districts.map(item => item.state_id))];
      let regions = [];

      for(let i = 0; i < filters.length; i++) {
        if(filters[i].name === 'regions') {
          filters[i].regions.forEach(region => {
            if(regionsIds.includes(region.id)) {
              regions.push(region);
            }
          });
          break;
        }
      }

      for(let i = 0; i < selectedFilters.location_state.length; i++) {
        regions.push(selectedFilters.location_state[i]);
      }

      const regionsItems = Object.values(
          regions.reduce((acc, obj) => ({ ...acc, [obj.id]: obj }), {})
      );

      this.$store.dispatch('updateFiltersByType', {
        selected_list_type: 'regions',
        role: this.role,
        filter_name: 'location_state',
        location_state: regionsItems,
      });
    }

    // countSettlementsInDistrict() {
    //   let count = 0

    //   for (const settlement of this.getSelectedFiltersByRoleAndNameOfFilter(
    //     this.role,
    //     this.filterName
    //   )) {
    //     if (
    //       settlement.district_id ===
    //       this.getSelectedFiltersByRoleAndNameOfFilter(
    //         this.role,
    //         'location_district'
    //       )
    //     ) {
    //       count++
    //     }
    //   }

    //   return count
    // },
  },
}
</script>

