<template>
  <div class="filter-options__list">
    <div class="filter-content filter-content__wrapper">
      <h4 class="filter-content__title">
        {{ $t('filter.hotel_star_rating') }}
      </h4>
      <!-- <div v-for="(item, index) in starsRatings" :key="index" class="stars__block"> -->
      <star-rating
        v-model="currentRate"
        :active-on-click="true"
        :star-size="18"
        :fixed-points="1"
        :show-rating="true"
        :clearable="true"
        :padding="8"
        :border-width="2"
        border-color="#6B6B6B"
        active-border-color="#FFB33B"
        active-color="#FFB33B"
        inactive-color="#fff"
      />
      <!-- </div> -->
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import StarRating from 'vue-star-rating'
export default {
  components: {
    StarRating,
  },
  props: {
    role: {
      type: String,
      default: 'hotel',
    },
    name: {
      type: String,
      default: '',
    },
    filterName: {
      type: String,
      default: 'hotel_star_category',
    },
  },
  computed: {
    ...mapGetters(['getClearData', 'getSelectedFiltersByRoleAndNameOfFilter', 'getCerrentRatingFilterByHotel']),
  },

  mounted() {
    this.currentRate = this.getSelectedFiltersByRoleAndNameOfFilter(
      this.role,
      'hotel_star_category'
    )[0]?.id[0]
  },

  watch: {
    currentRate(val) {

      this.$store.dispatch('updateFiltersByType', {
        selected_list_type: this.filterName,
        role: this.role,
        filter_name: this.filterName,
        // [this.filterName]: `${val}, ${val}`,
        [this.filterName]: !!val ? [{ id: `${val},${val}` }] : '',
      })
    },

    getCerrentRatingFilterByHotel(val) {
      if(!this.getCerrentRatingFilterByHotel) {
        this.currentRate = 0
      }
    }
  },

  data() {
    return {
      currentRate: 0,
    }
  },
}
</script>

