<template>
  <!-- <div> -->
  <CurrentCheckoxesList
    :filter-name="filterName"
    :name="name"
    :role="role"
    :level="level"
    :currentFiltersList="
      getFilterListOfTerritories(role, 'regions', 'districts')
    "
    :selected-items="getSelectedFiltersByRoleAndNameOfFilter(role, filterName)"
    @set-selected-list="getSelectedList($event)"
    @set-list="setList($event)"
    @hover-block-name="hoverHandler($event)"
  />
  <!-- :currentFilterListId="listId" -->
  <!-- </div> -->
</template>

<script>
import { mapGetters } from 'vuex'
import CurrentCheckoxesList from './CurrentCheckoxesList.vue'
export default {
  components: { CurrentCheckoxesList },

  props: {
    role: {
      type: String,
      default: '',
    },

    name: {
      type: String,
      default: 'district',
    },
    level: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      currFilterList: [],
      listId: 0,
      filterName: 'location_district',
    }
  },

  computed: {
    ...mapGetters([
      'getFilterListOfTerritories',
      'getSelectedFiltersByRoleAndNameOfFilter',
      'getLocationDistricts',
      'getLocationSettlements',
      'getCurrentFilterListyByName',
      'getSelectedFiltersByRole',
      // 'getDistrictListId',
    ]),
  },
  async created() {
    this.currFilterList = this.getLocationDistricts?.map((el) => {
      return {
        ...el,
        nextBlockName: 'settlement',
        isShow: false,
        isHasList: true,
      }
    })
  },

  watch: {
    getLocationDistricts(val) {
      this.currFilterList = val.map((el) => {
        return {
          ...el,
          nextBlockName: 'settlement',
          isShow: false,
          isHasList: true,
        }
      })
    },
  },
  methods: {
    async setList(item) {
      // this.listId = +item.id
      await this.$store.dispatch('getLocationSettlements', {
        district_id: item.id,
      })
    },

    async getSelectedList(selectedDistricts) {

      const filters = this.getCurrentFilterListyByName(this.role);
      const selectedFilters = this.getSelectedFiltersByRole(this.role)

      this.setRegionFilter(selectedDistricts, filters, selectedFilters)
      this.uncheckChildren(selectedDistricts, filters, selectedFilters)

      const selectedDistrictsResult = Object.values(
          selectedDistricts.reduce((acc, obj) => ({ ...acc, [obj.id]: obj }), {})
      );

      this.$store.dispatch('updateFiltersByType', {
        selected_list_type: 'districts',
        role: this.role,
        filter_name: this.filterName,
        [this.filterName]: selectedDistrictsResult,
      })
    },

    setRegionFilter(selectedDistricts, filters, selectedFilters) {
      const regionsIds = [...new Set(selectedDistricts.map(item => item.state_id))];
      let regions = [];

      for(let i = 0; i < filters.length; i++) {
        if(filters[i].name === 'regions') {
          filters[i].regions.forEach(region => {
            if(regionsIds.includes(region.id)) {
              regions.push(region);
            }
          });
          break;
        }
      }

      for(let i = 0; i < selectedFilters.location_state.length; i++) {
        regions.push(selectedFilters.location_state[i]);
      }

      const regionItems = Object.values(
          regions.reduce((acc, obj) => ({ ...acc, [obj.id]: obj }), {})
      );

      regionItems.forEach(region => {
        region.counter = 0
        selectedDistricts.forEach(el => {
          if(el.state_id === region.id) {
            region.counter++
          }
        })
      })

      this.$store.dispatch('updateFiltersByType', {
        selected_list_type: 'regions',
        role: this.role,
        filter_name: 'location_state',
        location_state: regionItems,
      });
    },

    uncheckChildren(selectedDistricts, filters, selectedFilters) {
      const districtIds = [...new Set(selectedDistricts.map(item => item.id))];
      const selected = []

      for(let i = 0; i < selectedFilters.location_settlement.length; i++) {
        if(districtIds.includes(selectedFilters.location_settlement[i].district_id)) {
          selected.push(selectedFilters.location_settlement[i])
        }
      }

      this.$store.dispatch('updateFiltersByType', {
        selected_list_type: 'settlements',
        role: this.role,
        filter_name: 'location_settlement',
        location_settlement: selected,
      });
    },

    hoverHandler(block) {
      this.$emit('hover-block-name', block)
    },
  },
}
</script>

