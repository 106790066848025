<template>
  <CurrentCheckoxesList
    :name="name"
    :role="role"
    :currentFiltersList="getCurrentFilterListyByName(name)"
    :selected-items="getSelectedFiltersByRoleAndNameOfFilter(role, filterName)"
    :filter-name="filterName"
    @set-selected-list="getSelectedList($event)"
  />
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import CurrentCheckoxesList from './CurrentCheckoxesList.vue'
import VueI18n from '@/i18n'

export default {
  components: { CurrentCheckoxesList },
  props: {
    role: {
      type: String,
      default: 'tour_operator',
    },

    name: {
      type: String,
      default: 'activity_type',
    },

    filterName: {
      type: String,
      default: 'tour_operator_type_of_activity',
    },
  },

  computed: {
    ...mapGetters([
      'getCurrentFilterListyByName',
      'getSelectedFiltersByRoleAndNameOfFilter',
    ]),
  },

  data() {
    return {
      type: 'activity_type',
      selectedList: [],
    }
  },

  methods: {
    getSelectedList(val) {
      this.$store.dispatch('updateFiltersByType', {
        selected_list_type: 'tour_operator_type_of_activity',
        role: this.role,
        filter_name: this.filterName,
        [this.filterName]: val,
      })
    },
  },
}
</script>

