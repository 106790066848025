<template>
  <!-- <div style="width: 100%"> -->
  <!-- {{ getFilterListOfTerritories(role, 'regions') }} -->
  <!-- {{ getSelectedFiltersByRoleAndNameOfFilter(role, filterName) }} -->
  <!-- {{ role }}  -->
  <CurrentCheckoxesList
    :filter-name="filterName"
    :role="role"
    :name="name"
    :level="level"
    :currentFilterListId="listId"
    :selected-items="getSelectedFiltersByRoleAndNameOfFilter(role, filterName)"
    :currentFiltersList="getFilterListOfTerritories(role, 'regions', 'regions')"
    @set-list="setList($event)"
    @hover-block-name="hoverHandler($event)"
    @set-selected-list="getSelectedList($event)"
  />
  <!-- </div> -->
</template>

<script>
import { mapGetters } from 'vuex'
import CurrentCheckoxesList from './CurrentCheckoxesList.vue'

export default {
  components: { CurrentCheckoxesList },

  props: {
    name: {
      type: String,
      default: 'region',
    },
    filterName: {
      type: String,
      default: 'location_state',
    },
    level: {
      type: Number,
      default: 1,
    },
    role: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      listId: 0,
    }
  },

  computed: {
    ...mapGetters([
      'getSelectedFiltersByRoleAndNameOfFilter',
      'getFilterListOfTerritories',
      'getCurrentFilterListyByName',
      'getSelectedFiltersByRole',
    ]),
  },

  // watch: {
  //   role: {
  //     handler: async function (val) {
  //       // this.$store.dispatch('updateFiltersByType', {
  //       //   role: this.role,
  //       //   filter_name: this.filterName,
  //       //   selectedRegions: this.selectedList,
  //       // })
  //     },
  //     immediate: true,
  //   },
  // },

  async created() {
    // get Regions list
    await this.$store.dispatch('getLocationState')
  },

  methods: {
    async setList(item) {
      this.listId = item.id

      await this.$store.dispatch('getLocationDistricts', {
        state_id: item.id,
      })
    },

    async getSelectedList(selectedRegions) {
      const selectedFilters = this.getSelectedFiltersByRole(this.role)

      const selectedRegionsItems = Object.values(
          selectedRegions.reduce((acc, obj) => ({ ...acc, [obj.id]: obj }), {})
      );

      this.uncheckChildren(selectedRegionsItems, selectedFilters)

      this.$store.dispatch('updateFiltersByType', {
        selected_list_type: 'regions',
        role: this.role,
        filter_name: this.filterName,
        [this.filterName]: selectedRegionsItems,
      })
    },

    uncheckChildren(selectedRegionsItems, selectedFilters) {
      const regionIds = [...new Set(selectedRegionsItems.map(item => item.id))];
      const selectedDistricts = [];

      for(let i = 0; i < selectedFilters.location_district.length; i++) {
        if(regionIds.includes(selectedFilters.location_district[i].state_id)) {
          selectedDistricts.push(selectedFilters.location_district[i])
        }
      }

      this.$store.dispatch('updateFiltersByType', {
        selected_list_type: 'districts',
        role: this.role,
        filter_name: 'location_district',
        location_district: selectedDistricts,
      });

      const districtIds = [...new Set(selectedFilters.location_district.map(item => item.id))];
      const selectedSettlements = [];

      for(let i = 0; i < selectedFilters.location_settlement.length; i++) {
        if(districtIds.includes(selectedFilters.location_settlement[i].district_id)) {
          selectedSettlements.push(selectedFilters.location_settlement[i])
        }
      }

      this.$store.dispatch('updateFiltersByType', {
        selected_list_type: 'settlements',
        role: this.role,
        filter_name: 'location_settlement',
        location_settlement: selectedSettlements,
      });
    },

    hoverHandler(block) {
      // this.nexBlockElementName = name
      this.$emit('hover-block-name', block)
    },
  },
}
</script>

