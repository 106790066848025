<template>
  <div class="filter-options__list">
    <div class="filter-content filter-content__wrapper">
      <h4 class="filter-content__title">
        {{ $t(`filter.${name}`) }}
      </h4>

      <div>
        <h4 class="filter-content__sub-title mb-2">
          {{ $t(`filter.hotel_capacity_of_conference_halls`) }}:
        </h4>
        <RangeField v-model="getRange" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import RangeField from '@/elements/RangeField.vue'
export default {
  components: { RangeField },

  props: {
    role: {
      type: String,
      default: 'hotel',
    },

    name: {
      type: String,
      default: 'beds_quantity',
    },
    filterName: {
      type: String,
      default: 'hotel_total_beds_quantity',
    },
  },
  data() {
    return {
      hotel_total_beds_quantity: '',
      from: null,
      to: null,
    }
  },

  watch: {},

  computed: {
    ...mapGetters(['getClearData']),

    getRange: {
      get() {
        return { from: this.from, to: this.to }
      },
      set(val) {
        this.from = val.from
        this.to = val.to
        if (!!this.from && !!this.to) {
          this.hotel_total_beds_quantity = `${this.from},${this.to}`
          this.$store.dispatch('updateFiltersByType', {
            selected_list_type: 'hotel_total_beds_quantity',
            role: this.role,
            filter_name: 'hotel_total_beds_quantity',
            [this.filterName]: [{ id: this.hotel_total_beds_quantity }],
          })
        }
      },
    },
  },
}
</script>

