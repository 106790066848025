<template>
  <div class="range-field range-field__wrapper">
    <div class="range-field__item">
      <div class="range-field__title">{{ $t(`main.${text[0]}`) }}</div>
      <input
        v-model.number="getMinValue"
        type="number"
        class="range-field__input"
        :class="{ 'range-field__input_active': !!getMinValue }"
      />
    </div>
    <div class="range-field__item">
      <div class="range-field__title">{{ $t(`main.${text[1]}`) }}</div>
      <input
        v-model.number="getMaxValue"
        type="number"
        class="range-field__input"
        :class="{ 'range-field__input_active': !!getMaxValue }"
      />
    </div>
  </div>
</template>

<script>
export default {
  model: {
    prop: 'value',
    event: 'input',
  },

  props: {
    value: {
      type: Object,
      default: '',
    },
    text: {
      type: Array,
      default: () => ['from', 'to'],
    },
  },

  data() {
    return {
      minValue: 1,
      maxValue: 999,
    };
  },
  computed: {
    getMinValue: {
      get() {
        return this.value.from;
      },
      set(val) {
        let computedValue = val;
        if (val < this.minValue) {
          computedValue = this.minValue;
        }
        if (val >= this.maxValue) {
          computedValue = this.maxValue - 1;
        }
        if (!!this.value.to && val >= this.value.to) {
          computedValue = this.value.to - 1;
        }

        this.$emit('input', { ...this.value, from: computedValue });
      },
    },
    getMaxValue: {
      get() {
        return this.value.to;
      },
      set(val) {
        let computedValue = val;
        if (val <= this.minValue) {
          computedValue = this.minValue + 1;
        }
        if (val >= this.maxValue) {
          computedValue = this.maxValue;
        }
        if (!!this.value.from && val <= this.value.from) {
          computedValue = this.value.from + 1;
        }

        this.$emit('input', { ...this.value, to: computedValue });
      },
    },
  },
  methods: {},
};
</script>

<style lang="sass" scoped>
.range-field__wrapper
  display: flex
  justify-content: space-between

.range-field__item
  width: 45%

.range-field__title
  font-size: 12px
  color: $gray
  margin-bottom: 10px

.range-field__input
  width: 100%
  color: $gray_notification
  border-bottom: solid 2px $gray
  padding-bottom: 4px
  transition: all 0.15s

.range-field__input:focus, .range-field__input_active
  border-color: $black
  transition: all 0.15s

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button
  -webkit-appearance: none
  -moz-appearance: none
  appearance: none
</style>
