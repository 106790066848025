<template>
  <div
    class="filter-counter"
    :class="{ 'filter-counter__mobile': getIsTouchDevice }"
  >
    <div class="filter-counter__wrapper">
      <div class="filter-counter__value">{{ value }}</div>
      <div class="filter-counter__clear" @click="clearHandler">
        <svg-icon name="close" class="filter-counter__ico" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  props: {
    value: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {};
  },

  computed: {
    ...mapGetters(['getIsTouchDevice']),
  },

  methods: {
    clearHandler() {
      this.$emit('clear');
    },
  },
};
</script>

<style lang="sass" scoped>
.filter-counter__wrapper
  width: 17px
  height: 17px
  background: $black
  border-radius: 50%
  color: $white
  font-size: 10px
  text-align: center
  line-height: 17px
  position: relative
  &:hover
    .filter-counter__value
      display: none
    .filter-counter__clear
      display: flex

.filter-counter__clear
  width: 100%
  height: 100%
  position: absolute
  left: 0
  top: 0
  display: none
  justify-content: center
  align-items: center


.filter-counter__ico
  width: 9px
  height: 9px
  fill: $white

.filter-counter__mobile
  position: relative
  // border: solid 1px red
  .filter-counter__wrapper
    transform: translateX(25px)
  .filter-counter__value
    display: block
  .filter-counter__clear
    display: flex
    width: 17px
    height: 17px
    background: $black
    border-radius: 50%
    left: -25px
</style>
