<template>
  <div class="search__result_content">
    <div class="search__result-content">
      <div class="header__search_container">
        <input-search
          class="head_block__search"
          placeholder="Реєстраційний номер документа, адреса..."
          isFilter
          @detectSearchValue="searchTableHandler"
        />
        <button class="filter__btn" @click="showFilterMenu">
          <svg-icon name="filter" class="filter__icon" />
          {{ $t('main.filter') }}
          <svg-icon
            v-if="isFilter"
            name="close-elipse"
            class="close__filter_icon"
          />
        </button>
      </div>

      <div class="filter__selected_filters filters_block">
        <div
          v-if="isShowTypeTourOpertor"
          class="filters_block__item filters_block__item--type"
        >
          <span>Тип:</span>
          <b>
            <span>{{ $t(`filter.tour_operator`) }}</span>
          </b>
          <div
            class="filter-counter__clear"
            @click="clearAllTourOperatorFilters()"
          >
            <svg-icon name="close" class="filter-counter__ico" />
          </div>
        </div>

        <div
          v-for="(value, index) in Object.entries(
            getSelectedFiltersByRole('tour_operator')
          )"
          v-if="!!value[1] && value[1].length > 0"
          :key="`tour_operator_${index}`"
          class="filters_block__item-wrapper"
        >
          <div
            v-if="Array.isArray(value[1])"
            v-for="(item, index) in value[1]"
            class="filters_block__item"
          >
            <span
              v-if="
                value[0] === 'hotel_number_of_conference_halls' ||
                value[0] === 'hotel_capacity_of_conference_halls'
              "
              >{{ $t(`filter.${value[0]}_item`) }}</span
            >
            <span v-else>{{ $t(`filter.${value[0]}`) }}</span>

            <b>
              <span>{{ item.name ? item.name : item.id }}</span>
            </b>

            <div
              class="filter-counter__clear"
              @click="clearFiltersByRole(value, item.id, 'tour_operator')"
            >
              <svg-icon name="close" class="filter-counter__ico" />
            </div>
          </div>
        </div>

        <div
          v-if="isShowTypeHotel"
          class="filters_block__item filters_block__item--type"
        >
          <span>Тип:</span>
          <b>
            <span>{{ $t(`filter.hotel`) }}</span>
          </b>
          <div class="filter-counter__clear" @click="clearAllHotelFilters()">
            <svg-icon name="close" class="filter-counter__ico" />
          </div>
        </div>

        <div
          v-for="(value, index) in Object.entries(
            getSelectedFiltersByRole('hotel')
          )"
          v-if="!!value[1] && value[1].length > 0"
          :key="`hotel_${index}`"
          class="filters_block__item-wrapper"
        >
          <div
            v-if="Array.isArray(value[1])"
            v-for="(item, index) in value[1]"
            class="filters_block__item"
          >
            <span
              v-if="
                value[0] === 'hotel_number_of_conference_halls' ||
                value[0] === 'hotel_capacity_of_conference_halls'
              "
              >{{ $t(`filter.${value[0]}_item`) }}</span
            >
            <span v-else>{{ $t(`filter.${value[0]}`) }}</span>

            <b>
              <span v-if="value[0] === 'hotel_star_category'">{{ item.id.split(',')[0] }}</span>
              <span v-else>{{ item.name ? item.name.replace(',', '-') : item.id.replace(',', '-') }}</span>
            </b>

            <div
              class="filter-counter__clear"
              @click="clearFiltersByRole(value, item.id, 'hotel')"
            >
              <svg-icon name="close" class="filter-counter__ico" />
            </div>
          </div>
        </div>
      </div>

      <div v-if="isFiltered || isSearched" class="filter__prompt_message">
        <div>{{ $t('main.enter_three_symbols') }}</div>
        <div class="filter__prompt_message-results">{{ $t('filter.results_total') }}: {{ getRegisterDataTotalQty }}</div>
      </div>
      <FilterOptions v-if="isFilter" @filtered="onFiltered" />
      <div class="table__search_result">
        <!-- {{ getCurrentFilter }} -->
        <Table
          v-if="!!getRegisterData"
          :header="getFilterHeaderData"
          :content="getRegisterData"
          :searchResults="true"
          @tableHandler="tableHandler"
          class="search-result-table"
        />
        <v-skeleton-loader v-else type="table" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import FilterOptions from '../register/filter2/FilterOptions.vue'
// import FilterOptions from '../register/filter/FilterOptions.vue';
import Table from '@/elements/Table.vue'

export default {
  components: { FilterOptions, Table },
  data() {
    return {
      isFilter: false,
      isSerarch: false,
      isFiltered: false,
      isSearched: false,
    }
  },

  async created() {
    if (!!this.$route.params.query) {
      this.searchTableHandler(this.$route.params.query)
      this.$route.params.query = null
    }

    if(this.getSelectedFiltersCounter > 0) {

      let role = 'tour_operator';

      for (const [key, value] of Object.entries(this.getSelectedFiltersByRole('hotel'))) {
        if(!!value && value.length > 0) {
          role = 'hotel';
          break;
        }
      }

      await this.$store.dispatch('getRegistryPageTableData', {
        role: role,
      });
    } else {
      this.$store.dispatch('getRegistryTableData')
    }
  },

  computed: {
    ...mapGetters([
      'getFilterTooltip',
      'getCurrentFilter',
      'getCurrentFilterRole',
      'getFilterHeaderData',
      'getRegisterData',
      'getSelectedFiltersByRole',
      'isSelectedFiltersBackEnd',
      'getSelectedFiltersCounter',
      'getRegisterDataTotalQty',
    ]),

    isShowTypeHotel() {
      return this.isSelectedFiltersBackEnd.isHotelFiltersSelected
    },

    isShowTypeTourOpertor() {
      return this.isSelectedFiltersBackEnd.isTourOperatorFiltersSelected
    },
  },

  watch: {
    getSelectedFiltersCounter() {
      if(this.isFiltered && this.getSelectedFiltersCounter == 0) {
        this.isFiltered = false
      }
    }
  },

  methods: {
    clearAllHotelFilters() {
      this.$store.commit('SET_CLEAR_HOTEL_FILTERS')
      if (this.isSelectedFiltersBackEnd.isTourOperatorFiltersSelected) {
        this.$store.dispatch('getRegistryPageTableData', {
          role: 'tour_operator',
        })
      } else {
        this.$store.dispatch('getRegistryPageTableData', {
          role: 'all',
        })
      }
    },
    clearAllTourOperatorFilters() {
      this.$store.commit('SET_CLEAR_TOUR_OPERATOR_FILTERS')
      if (this.isSelectedFiltersBackEnd.isHotelFiltersSelected) {
        this.$store.dispatch('getRegistryPageTableData', {
          role: 'hotel',
        })
      } else {
        this.$store.dispatch('getRegistryPageTableData', {
          role: 'all',
        })
      }
    },

    clearFiltersByRole(keyAndValue, itemID, role) {
      const filterName = keyAndValue[0]

      if (
        filterName === 'location_state' ||
        filterName === 'location_district' ||
        filterName === 'location_settlement' ||
        filterName === 'tour_operator_type_of_activity' ||
        filterName === 'date_of_state_registration'
      ) {
        this.$store.dispatch('updateFiltersByType', {
          selected_list_type:
            filterName === 'location_state'
              ? 'regions'
              : filterName === 'location_district'
              ? 'districts'
              : filterName === 'location_settlement'
              ? 'settlements'
              : filterName,
          role: role,
          filter_name: filterName,
          [filterName]: keyAndValue[1].filter((item) => item.id !== itemID),
        })
      } else {
        this.$store.dispatch('updateFiltersByType', {
          selected_list_type: filterName,
          role: role,
          filter_name: filterName,
          [filterName]: [],
        })
      }

      if (!this.isSelectedFiltersBackEnd.isBothRoleFiltersSelected) {
        if (!this.isSelectedFiltersBackEnd.isTourOperatorFiltersSelected) {
          this.$store.dispatch('getRegistryPageTableData', {
            role: 'hotel',
          })
        }
        if (!this.isSelectedFiltersBackEnd.isHotelFiltersSelected) {
          this.$store.dispatch('getRegistryPageTableData', {
            role: 'tour_operator',
          })
        }
      } else if (this.isSelectedFiltersBackEnd.isSomeFiltersSelected) {
        this.$store.dispatch('getRegistryPageTableData', {
          role: role,
        })
      } else {
        this.$store.dispatch('getRegistryPageTableData', {
          role: 'all',
        })
      }
    },

    showFilterMenu() {
      this.isFilter = !this.isFilter
    },

    tableHandler() {
      this.$store.dispatch('getRegistryPageTableData', {
        role: this.getCurrentFilterRole,
      })
    },

    searchTableHandler(val) {
      this.isSerarch = true
      this.$store.commit('SET_TABLE_SORT_DATA', { search: val })
      if (val?.length > 3 || val?.length <= 0) {
        this.$store.dispatch('getRegistryTableData')
      }

      if(val?.length > 3) {
        this.isSearched = true
      } else if(val?.length <= 0) {
        this.isSearched = false
      }
    },

    onFiltered(e) {
      this.isFiltered = e
    }
  },
}
</script>

<style lang="sass">
.filters_block
  display: flex
  flex-wrap: wrap
  gap: 8px
  margin: 8px 0
  max-height: 50vh
  overflow-y: auto

.filters_block__item-wrapper
  flex-wrap: wrap
  display: flex
  gap: 8px

.filters_block__item
  display: flex
  align-items: center
  justify-content: center
  width: fit-content
  padding: 10px 16px
  font-size: 14px
  line-height: 17px
  gap: 10px
  border-radius: 50px
  border: 1px solid

.filters_block__item--type
  border: none
  width: 100%
  text-align: left
  justify-content: flex-start

.filter-counter__clear
  cursor: pointer
  width: 18px
  height: 18px
  display: flex
  background-color: black
  border-radius: 50%
  justify-content: center
  align-items: center


.filter-counter__ico
  width: 9px
  height: 9px
  fill: $white


.search__result_content
  height: 100%

.table__search_result .table__container .table__header, .table__search_result .table__container .table__report
  // grid-template-columns: 26% 26% 14% 14% 14% 6%
  @include xs
  // padding-top: 29px
.table__search_result .table__content .table__report.items__container div:nth-child(5) p
  @include xs
    display: none
.table__search_result .table__content .table__report div div
  height: auto
.table__search_result .table__content .table__report.items__container div
  height: auto
  display: -webkit-box
  -webkit-line-clamp: 2
  -webkit-box-orient: vertical
  overflow: hidden
  text-overflow: ellipsis
  line-height: 1.5
  font-size: 16px
  @include xs
    text-align: left
    padding: 8px 0
    font-size: 12px
    -webkit-line-clamp: initial

.table__search_result .table__content .table__report.items__container div:last-child
  position: initial
.no__result_text
  font-size: 14px
  color: #B6B6B6
.no__result_main
  text-align: center
  font-size: 32px
  color: #B6B6B6
  margin-top: 50px
.table__search_result  .table__menu-btn
  display: flex
  align-items: center
  text-decoration: underline
.table__search_result  .table__menu-btn svg
  margin-right: 10px
.sub__option p
  width: 100%

.v-select:not(.v-text-field--single-line):not(.v-text-field--outlined) .v-select__slot > input
  font-size: 12px
  padding: 7px 0
div.input-search.input-search__form
  width: 100%
  // height: auto
  position: relative

.search__result-content #input-search
  //min-width: 580px
  //max-width: 600px
  background-color: $gray_border
  border-radius: 50px
  height: 50px
  border-bottom: none
  padding-left: 184px
  @include xs
    padding-left: 60px
    min-width: auto
  @include xxs
    padding-left: 32px
    padding-right: 10px
    overflow: hidden
    text-overflow: ellipsis

.search__result-content .input-search__form .icon-magnifier
  right: 0em
  left: auto
  @include xs
    top: 18px
    left: 10px
  @include xxs
    left: 0px

.search__result-content
  background: white
  padding: 32px 64px
  height: 100%
  @include xs
    padding: 20px 18px 0 18px

.input-search__wrapper svg.svg-icon.icon-search
  top: 50%
  transform: translateY(-50%)
  right: 24px
  max-width: 16px
  position: absolute
  left: auto
  max-width: 24px

.filter__btn
  position: absolute
  top: 0
  height: 100%
  width: 150px
  display: flex
  align-items: center
  justify-content: center
  color: $white
  border-radius: 50px
  background-color: $black
  height: 50px
  top: 50%
  transform: translateY(-50%)
  @include xs
    position: static
    margin-top: 40px

.header__search_container
  position: relative

svg.filter__icon
  width: 18px
  height: 12px
  margin-right: 10px

.close__filter_icon
  width: 18px
  height: 18px
  margin-left: 12px

.filter__prompt_message
  margin-top: 24px
  margin-bottom: 40px
  color: $gray
  font-weight: 300

  &-results
    margin-top: 1rem
  @include xs
    margin-top: 0

.earch__result_content .input-search__btn-clear
  right: 10px
  left: auto
</style>

