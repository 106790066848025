<template>
  <div class="filter-options__list">
    <div class="filter-content filter-content__wrapper">
      <h4 class="filter-content__title">
        {{ $t(`filter.${name}`) }}
      </h4>
      <div class="">
        <h4 class="filter-content__sub-title">
          {{ $t(`filter.hotel_number_of_conference_halls`) }}:
        </h4>
        <TextField v-model="getNumberRoom" is-integer />
      </div>
      <div>
        <h4 class="filter-content__sub-title mb-2">
          {{ $t(`filter.hotel_capacity_of_conference_halls`) }}:
        </h4>
        <RangeField v-model="getRange" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import RangeField from '@/elements/RangeField.vue'
export default {
  components: { RangeField },

  props: {
    role: {
      type: String,
      default: 'hotel',
    },

    name: {
      type: String,
      default: 'conference_halls',
    },
    filterName: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      number_rooms: '',
      hotel_conference_halls: '',
      from: null,
      to: null,
    }
  },

  watch: {},

  computed: {
    ...mapGetters(['getClearData']),

    getNumberRoom: {
      get() {
        return this.number_rooms
      },
      set(val) {
        this.number_rooms = val
        this.$store.dispatch('updateFiltersByType', {
          selected_list_type: 'hotel_number_of_conference_halls',
          role: this.role,
          filter_name: 'hotel_number_of_conference_halls',
          hotel_number_of_conference_halls: [{ id: `0,${this.number_rooms}` }],
        })
      },
    },

    getRange: {
      get() {
        return { from: this.from, to: this.to }
      },
      set(val) {
        this.from = val.from
        this.to = val.to
        if (!!this.from && !!this.to) {
          this.hotel_conference_halls = `${this.from}-${this.to}`

          this.$store.dispatch('updateFiltersByType', {
            selected_list_type: 'hotel_capacity_of_conference_halls',
            role: this.role,
            filter_name: 'hotel_capacity_of_conference_halls',
            hotel_capacity_of_conference_halls: [
              { id: this.hotel_conference_halls },
            ],
          })
        }
      },
    },
  },
}
</script>

